$(document).ready(function(){
	ldMain();
});

/* Main Menu functions */
function ldContent(htmlfile) {
    $(".content").load("/" + htmlfile + ".html?" + Math.random());
}

function ldMain() {
    $(".content").load("/main.html?" + Math.random());
}

function ldAbout() {
    $(".content").load("/about.html?" + Math.random());
}

function ldTalkGen() {
    $(".content").load("/talk-gen-start.html?" + Math.random());
}

function ldTalkTech() {
    $(".content").load("/talk-tech-start.html?" + Math.random());
}

function ldTalkApua() { 
    $(".content").load("/talk-aqua-start.html?" + Math.random());
}

function ldLegal() { 
    $(".content").load("/legal.html?" + Math.random());
}
/* End: Main Menu functions */
